import React, { useState, useRef, useEffect } from "react"
// import { useStaticQuery, graphql } from 'gatsby';
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Columns,
  Column,
  Title,
  SubTitle,
  Button,
  Input,
  Select,
  Span,
} from "../../../StyleComponents/styles"
import { Figure, FlexDiv } from "../../../StyleComponents/pagesStyle"
import { isSSR } from "../../../components/Script/script"

const GoogleAds = React.lazy(() => import("../../../components/ADS/ADS"))
const AgeTimeZoneOption = React.lazy(() =>
  import("../../../componentForPages/AgeCalculatorTimeZone")
)

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  { name: "Age Calculator", link: "/age-calculation/" },
]
const seeAlsoArray = [
  "/date-and-time-calculator",
  "/fuel-consumption-calculator",
  "/percentage-calculator",
  "/rep-max-calculator",
  "/shopping-calculator",
  "/tax-cost-calculator",
  "/tip-calculator",
]

function AgeCalculation(props) {
  let [parYears, setparYears] = useState("")
  let [parMonths, setparMonths] = useState("")
  let [parDays, setparDays] = useState("")
  let [parHours, setparHours] = useState("")
  let [parMinutes, setparMinutes] = useState("")
  let [parSeconds, setparSeconds] = useState("")
  let [message, setmessage] = useState("")
  let MyInput = useRef("")
  let timeOfClock = useRef("")
  let userTime = useRef("")
  let [ageToBornYear, setageToBornYear] = useState("")
  let [BornYear, setBornYear] = useState("")
  let [bornFullDate, setbornFullDate] = useState("")
  // let [nextBirthday, setnextBirthday] = useState("")
  const calcBornYear = e => {
    if (e.target.value >= 0 && e.target.value <= 120) {
      setageToBornYear(e.target.value)
      setBornYear(new Date().getFullYear() - e.target.value)
    }
  }
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  // const changeUserTime = e => {
  //   setuserTime(e.target.value)
  // }
  useEffect(() => {
    userTime.current.value = timezone
  }, [timezone])
  function calculate(e) {
    if (MyInput.current.value === "") {
      setmessage("Enter Your Birth Date, Or check if the date correct")
    } else {
      let input = MyInput.current.value,
        date = new Date(input),
        today = new Date(
          new Date().toLocaleString("en-US", {
            timeZone: `${userTime.current.value}`,
          })
        )
      let clock = timeOfClock.current.value
      if (clock !== undefined && clock !== "") {
        let arrayClock = clock.split(":")
        date.setHours(Number(arrayClock[0]), Number(arrayClock[1]))
      }

      let years, months, days, hours
      let todayYear = today.getFullYear(),
        todayMonth = today.getMonth(),
        todayDay = today.getDate(),
        todayHour = today.getHours()
      let birthdayYear = date.getFullYear(),
        birthdayMonth = date.getMonth(),
        birthdayDay = date.getDate(),
        birthdayHour = date.getHours()

      if (todayHour < birthdayHour) {
        hours = todayHour - birthdayHour + 24
        todayDay = todayDay - 1
      } else {
        hours = todayHour - birthdayHour
      }
      if (todayDay < birthdayDay) {
        days = todayDay - birthdayDay + 30
        todayMonth = todayMonth - 1
      } else {
        days = todayDay - birthdayDay
      }
      if (todayMonth < birthdayMonth) {
        months = todayMonth - birthdayMonth + 12
        todayYear = todayYear - 1
      } else {
        months = todayMonth - birthdayMonth
      }
      years = todayYear - birthdayYear
      let res = today - date
      if (res < 0) {
        res *= -1
        setmessage("Check if the date correct")
      } else {
        setmessage("Your Age")
        // let next = ""
        // if (12 - months <= 1) {
        // } else {
        //   next += 12 - months - 1 + " months "
        // }
        // if (30 - days <= 1) {
        // } else {
        //   next += 30 - days + " days "
        // }
        // if (24 - hours <= 1) {
        // } else {
        //   next += 24 - hours + " hours "
        // }

        // console.log(next)

        let seconds = Math.floor(res / 1000),
          mins = Math.floor(seconds / 60)
        // hours = Math.round(mins / 60)
        // days = Math.floor(hours / 24),
        // months = Math.floor(days / 30),
        // years = Math.floor(res / 1000 / 60 / 60 / 24 / 30 / 12)
        // days = days %= 30
        // months = months %= 12
        // hours = hours %= 24

        seconds %= 60
        mins = mins %= 60

        if (years < 10) {
          years = "0" + years
        }
        if (months < 10) {
          months = "0" + months
        }
        if (days < 10) {
          days = "0" + days
        }
        if (hours < 10) {
          hours = "0" + hours
        }
        if (mins < 10) {
          mins = "0" + mins
        }
        if (seconds < 10) {
          seconds = "0" + seconds
        }
        if (isNaN(res)) {
          setparYears("")
          setparMonths("")
          setparDays("")
          setparHours("")
          setparMinutes("")
          setparSeconds("")
        } else {
          setparYears(years)
          setparMonths(months)
          setparDays(days)
          setparHours(hours)
          setparMinutes(mins)
          setparSeconds(seconds)
          setbornFullDate(date.toDateString())
          // setnextBirthday("next")
        }
      }
      if (input === "") {
        setmessage("Enter Your Birth Date")
      }

      // setTimeout(calculate, 1000)
    }
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Age Calculator - Simple Age Calculation"
        description="Age Calculator, Simple Age Calculation  Years, Months, Days, Hours, Minutes, and Seconds with Countup Timer for your Age, step by step."
        keywords={[
          "Age Calculator, Simple Calculation Age, Age on Years, calculate age,my age, whats my age, age countup, age by seconds,calculation age, simple age, time from my birth date.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Columns>
          <Column>
            <Title>Age Calculator</Title>
            <SubTitle>if my birthday is &#x2015; how old am i</SubTitle>
            <br />
            <FlexDiv maxWidth="500px" className="mb-4">
              <Span>Enter Your Birth Date Here:</Span>
              <Input type="date" id="date" aria-label="date" ref={MyInput} />
              <Span>Time Not Required:</Span>
              <Input
                type="time"
                aria-label="appt"
                min="09:00"
                max="18:00"
                ref={timeOfClock}
              />

              <Span>Timezone is:</Span>
              <br />
              <Select className="mb-4">
                <select
                  aria-label="time"
                  ref={userTime}
                  // onChange={changeUserTime}
                  // onBlur={changeUserTime}
                >
                  {!isSSR && (
                    <React.Suspense fallback={<option value="" />}>
                      <AgeTimeZoneOption />
                    </React.Suspense>
                  )}
                </select>
              </Select>

              <Button
                borderColor="#1da1f2"
                color="#1da1f2"
                hoverColor="white"
                hoverBorderColor="#1da1f2"
                hoverBackgroundColor="#1da1f2"
                onClick={calculate}
              >
                Calculate
              </Button>
            </FlexDiv>
            {/* /************************************************************************************************************ */}
            {message !== "" ? (
              <div>
                <br />
                <p className="h5">{message}</p>
              </div>
            ) : (
              ""
            )}
            <FlexDiv displayDiv="flex">
              <Figure>
                <strong>{parYears}</strong>
                <figcaption>Years</figcaption>
              </Figure>
              <Figure>
                <strong>{parMonths}</strong>
                <figcaption>Months</figcaption>
              </Figure>
              <Figure>
                <strong>{parDays}</strong>
                <figcaption>Days</figcaption>
              </Figure>
            </FlexDiv>
            <FlexDiv displayDiv="flex">
              <Figure>
                <strong>{parHours}</strong>
                <figcaption>Hours</figcaption>
              </Figure>
              <Figure>
                <strong>{parMinutes}</strong>
                <figcaption>Minutes</figcaption>
              </Figure>
              <Figure>
                <strong>{parSeconds}</strong>
                <figcaption>Seconds</figcaption>
              </Figure>
            </FlexDiv>
            <br />
            <br />
            {/* What year was I born? If my age is 12 If my age is 13 If my age is 14 If
        my age is 15 If my age is 16 */}
            {bornFullDate === "" ? (
              ""
            ) : (
              <div>
                <p>
                  You were born in: <span>{bornFullDate}</span>
                  <br />
                  Age:{" "}
                  <span>
                    {parYears} years {parMonths} months {parDays} days
                  </span>
                  <br />
                  Age in months:{" "}
                  <span>
                    {parYears * 12 + parMonths} months{" "}
                    {parDays !== 0 ? `${parDays} days` : ""}
                  </span>
                  <br />
                  Age in weeks:{" "}
                  <span>
                    {Math.round(
                      (parYears * 12 + parMonths) * 4.345 + parDays / 7
                    )}{" "}
                    weeks
                  </span>
                  <br /> Age in days:{" "}
                  <span>
                    {Math.round(parYears * 365 + parMonths * 30.417 + parDays)}{" "}
                    days
                  </span>
                  <br /> Age in hours:{" "}
                  <span>
                    {parYears * 8760 +
                      parMonths * 730 +
                      parDays * 24 +
                      parHours}{" "}
                    hours
                  </span>
                  <br /> Age in minutes:{" "}
                  <span>
                    {parYears * 525600 +
                      parMonths * 43800 +
                      parDays * 1440 +
                      parHours * 60 +
                      parMinutes}{" "}
                    minutes
                  </span>
                  <br /> Age in seconds:{" "}
                  <span>
                    {Math.round(
                      parYears * 3.154e7 +
                        parMonths * 2.628e6 +
                        parDays * 86400 +
                        parHours * 3600 +
                        parMinutes * 60
                    )}{" "}
                    seconds
                  </span>
                  {/* <br />
              Next birthday in:{" "}
              <span style={{ color: " #3298dc", fontSize: "1.4em" }}>
                {nextBirthday}
              </span> */}
                </p>
              </div>
            )}
          </Column>
          <Column maxWidth="300px">
            {!isSSR && (
              <React.Suspense fallback={<div />}>
                <GoogleAds
                  slot="1189740279"
                  data-ad-format="auto"
                  data-ad-layout-key="-gi+g+7v-1q-6r"
                  data-full-width-responsive="true"
                />
              </React.Suspense>
            )}
          </Column>
        </Columns>
        <br />
        <h4>what year was i born If my age is &#x2015;</h4>
        <div>
          <span>Your Age:</span>
          <br />
          <Input
            name="age"
            aria-label="age"
            type="number"
            min="0"
            max="120"
            value={ageToBornYear}
            onChange={calcBornYear}
            width="100px"
          />
          <div>
            {ageToBornYear === "" ? (
              ""
            ) : (
              <p className="mt-3">
                You Born at <span className="h5">{BornYear}</span>
              </p>
            )}
          </div>
        </div>
        <br />
        <div>
          <div>
            <h3>Age Calculator</h3>
            <p>
              Calculate Age gives you the answer of How old am I? enter your
              birth date, and press on the calculate button. Your Age will be
              given on "Years", "Months", "Days", "Hours", "Minutes", and
              "Seconds".
            </p>
          </div>
        </div>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default AgeCalculation
